:root {
  --regisafe-header-height: 104px;
  --regisafe-footer-height: 97px;
  --regisafe-footer-background: #F2F8FB;
  --regisafe-button-background: #F2F8FB;
  --regisafe-input-focused-background: #F2F8FB;
  --regisafe-button-background-light: #fff;
  --regisafe-button-background-danger: #FF8E95;
  --regisafe-button-color-danger: #F2F8FB;
  --regisafe-tabbar-with: 60px;
  --regisafe-tabbar-top: 56px;
  --regisafe-tabbar-height: calc(100% - (173px));
  --regisafe-tabbar-left: -65px;
  --regisafe-tabbar-background: transparent;
  --regisafe-tabbar-button-width: 50px;
  --regisafe-tabbar-button-height: 50px;
  --regisafe-tabbar-button-background: #FFF;
  --regisafe-tabbar-button-selected-background: #C8D3E2;
  --regisafe-font-color: #777777;
  --regisafe-danger-color: #FF3B30;
  --regisafe-icon-color-gray: rgba(54, 54, 54, 0.7);
  --regisafe-content-margin-left: 80px;
  --regisafe-content-margin-top: 0;
  --regisafe-content-margin-right: 10px; }

DIV.files-in-progress ION-LIST.search-results-list + DIV.progress-item-header {
  padding-top: 30px; }
DIV.files-in-progress DIV.progress-item-header {
  display: flex;
  margin-right: -15px;
  margin-bottom: 11px;
  align-items: center; }
  DIV.files-in-progress DIV.progress-item-header H3 {
    flex-basis: 100%;
    margin: 0;
    color: var(--regisafe-font-color);
    font-size: inherit; }
  DIV.files-in-progress DIV.progress-item-header BUTTON {
    background: transparent;
    font-size: 1.5rem;
    color: var(--regisafe-font-color);
    padding-right: 4px;
    padding-left: 5px;
    display: flex;
    align-items: center; }
  DIV.files-in-progress DIV.progress-item-header DIV.lock-icon {
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 18px; }
    DIV.files-in-progress DIV.progress-item-header DIV.lock-icon ION-ICON {
      color: var(--regisafe-danger-color);
      font-size: 1.5rem; }
  DIV.files-in-progress DIV.progress-item-header ION-ICON.orga-icon {
    font-size: 2rem;
    padding-right: 15px; }
DIV.files-in-progress ION-LIST {
  padding: 0; }
  DIV.files-in-progress ION-LIST ION-ITEM {
    --inner-padding-end: 5px !important; }
    DIV.files-in-progress ION-LIST ION-ITEM FA-ICON.filetype {
      font-size: 2.0rem;
      margin: 0 20px 0 0;
      color: var(--regisafe-icon-color-gray); }
    DIV.files-in-progress ION-LIST ION-ITEM ION-LABEL {
      pointer-events: none; }
      DIV.files-in-progress ION-LIST ION-ITEM ION-LABEL H3 {
        line-height: 21px;
        font-weight: 500; }
    DIV.files-in-progress ION-LIST ION-ITEM ION-ICON.checkmark {
      visibility: hidden; }
    DIV.files-in-progress ION-LIST ION-ITEM.marked {
      --background: #F2F2F2; }
      DIV.files-in-progress ION-LIST ION-ITEM.marked ION-ICON.checkmark {
        visibility: visible; }

/*# sourceMappingURL=regisafe-files-inprogress.css.map */
