:root {
  --regisafe-header-height: 104px;
  --regisafe-footer-height: 97px;
  --regisafe-footer-background: #F2F8FB;
  --regisafe-button-background: #F2F8FB;
  --regisafe-input-focused-background: #F2F8FB;
  --regisafe-button-background-light: #fff;
  --regisafe-button-background-danger: #FF8E95;
  --regisafe-button-color-danger: #F2F8FB;
  --regisafe-tabbar-with: 60px;
  --regisafe-tabbar-top: 56px;
  --regisafe-tabbar-height: calc(100% - (173px));
  --regisafe-tabbar-left: -65px;
  --regisafe-tabbar-background: transparent;
  --regisafe-tabbar-button-width: 50px;
  --regisafe-tabbar-button-height: 50px;
  --regisafe-tabbar-button-background: #FFF;
  --regisafe-tabbar-button-selected-background: #C8D3E2;
  --regisafe-font-color: #777777;
  --regisafe-danger-color: #FF3B30;
  --regisafe-icon-color-gray: rgba(54, 54, 54, 0.7);
  --regisafe-content-margin-left: 80px;
  --regisafe-content-margin-top: 0;
  --regisafe-content-margin-right: 10px; }

ION-CONTENT.regisafe-content DIV.inner-wrapper {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 0;
  margin-top: env(safe-area-inset-top); }
  ION-CONTENT.regisafe-content DIV.inner-wrapper.left {
    margin-left: var(--regisafe-content-margin-left);
    margin-top: var(--regisafe-content-margin-top);
    min-height: 100%; }
  ION-CONTENT.regisafe-content DIV.inner-wrapper.keyboard-show {
    margin-left: 0; }

ION-FOOTER.regisafe-footer {
  height: var(--regisafe-footer-height);
  margin-left: 0; }
  ION-FOOTER.regisafe-footer::before {
    display: none; }
  ION-FOOTER.regisafe-footer ION-TOOLBAR {
    --min-height: var(--regisafe-footer-height);
    --background: var(--regisafe-footer-background); }

.regisafe-button-primary {
  background-color: var(--regisafe-button-background);
  text-transform: none;
  text-align: center;
  height: 50px;
  position: relative;
  border-radius: 4px;
  min-width: 130px;
  color: var(--regisafe-font-color);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.29);
  font-size: inherit; }
  .regisafe-button-primary:disabled {
    opacity: 0.5; }
  .regisafe-button-primary.light {
    background-color: var(--regisafe-button-background-light); }
  .regisafe-button-primary.danger {
    background-color: var(--regisafe-button-background-danger);
    color: var(--regisafe-button-color-danger); }
  .regisafe-button-primary + .regisafe-button-primary {
    margin-top: 10px; }
  .regisafe-button-primary.block {
    width: 100%; }
  .regisafe-button-primary.icon-only {
    display: flex;
    align-items: center; }
  .regisafe-button-primary.padding-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .regisafe-button-primary FA-ICON {
    position: absolute;
    left: 10px; }

@media screen and (max-width: 520px) {
  .content-tool-bar BUTTON.regisafe-button-primary {
    width: 100%; } }

DIV.regisafe-search-toolbar {
  padding: 15px 20px 15px 0;
  display: flex;
  margin-left: 80px; }
  @media screen and (max-width: 767px) {
    DIV.regisafe-search-toolbar {
      flex-wrap: wrap; } }
  @media screen and (max-width: 520px) {
    DIV.regisafe-search-toolbar {
      flex-direction: column; } }
  DIV.regisafe-search-toolbar.center {
    justify-content: center; }
    DIV.regisafe-search-toolbar.center BUTTON.extra-pad {
      padding-right: 40px;
      padding-left: 40px; }
    @media screen and (max-width: 767px) {
      DIV.regisafe-search-toolbar.center BUTTON {
        margin-left: 0;
        width: 100%; } }
  DIV.regisafe-search-toolbar DIV.tool {
    flex: 1 1 0; }
    DIV.regisafe-search-toolbar DIV.tool:first-child {
      padding-right: 8px;
      text-align: right; }
    DIV.regisafe-search-toolbar DIV.tool:last-child {
      padding-left: 8px;
      text-align: left; }
    @media screen and (max-width: 520px) {
      DIV.regisafe-search-toolbar DIV.tool {
        padding: 0 0 15px 5px !important; }
        DIV.regisafe-search-toolbar DIV.tool BUTTON {
          width: 100%; } }

ION-FOOTER.regisafe-footer ION-SEGMENT {
  background-color: var(--regisafe-footer-background); }
  ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON {
    --padding-end: 5.5px;
    --padding-start: 5.5px;
    display: block; }
    ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON:first-child {
      --padding-start: 15px; }
    ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON DIV.wrapper {
      padding: 4px 4px 10px 3px;
      margin-top: 18px;
      position: relative; }
      ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON DIV.wrapper:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 90%;
        width: 100%;
        border-radius: 12px;
        background: white;
        background: linear-gradient(135deg, white 0%, white 35%, rgba(150, 150, 150, 0.2) 100%);
        /* Declaring our shadow color inherit from the parent (button) */
        filter: blur(2px);
        /* Transition for the magic */
        -webkit-transition: all 0.2s;
        transition: all 0.2s; }
      ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON DIV.wrapper DIV.inner-wrapper {
        height: 50px;
        width: 50px;
        background-color: var(--regisafe-footer-background);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center; }
        ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON DIV.wrapper DIV.inner-wrapper ION-ICON {
          font-size: 2rem; }
    ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON.segment-button-checked {
      --color-checked: transparent; }
      ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON.segment-button-checked DIV.wrapper:after {
        display: none; }
      ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON.segment-button-checked DIV.wrapper DIV.inner-wrapper {
        position: relative; }
        ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON.segment-button-checked DIV.wrapper DIV.inner-wrapper:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.2); }
        ION-FOOTER.regisafe-footer ION-SEGMENT ION-SEGMENT-BUTTON.segment-button-checked DIV.wrapper DIV.inner-wrapper:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          box-shadow: inset -4px -4px 8px white; }
ION-FOOTER.regisafe-footer.no-stretch ION-SEGMENT {
  justify-content: center; }
  @media screen and (max-width: 767px) {
    ION-FOOTER.regisafe-footer.no-stretch ION-SEGMENT {
      justify-content: left; } }
  ION-FOOTER.regisafe-footer.no-stretch ION-SEGMENT ION-SEGMENT-BUTTON {
    flex: 0; }

ION-LIST.search-results-list {
  padding-left: 0;
  padding-top: 0; }
  ION-LIST.search-results-list ION-LIST-HEADER ION-LABEL {
    display: flex;
    color: var(--regisafe-font-color); }
    ION-LIST.search-results-list ION-LIST-HEADER ION-LABEL P {
      font-size: 1.3rem;
      color: var(--regisafe-font-color); }
    ION-LIST.search-results-list ION-LIST-HEADER ION-LABEL ION-ICON {
      font-size: 1.5rem;
      margin: 0 10px 0 0;
      min-width: 24px; }
  ION-LIST.search-results-list ION-ITEM {
    --background: #FBFBFB;
    --border-color: transparent;
    margin-bottom: 15px;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: #FBFBFB;
    --padding-start: 10px;
    --inner-padding-end: 7px !important;
    border-radius: 8px; }
    ION-LIST.search-results-list ION-ITEM ION-AVATAR {
      margin-right: 0; }
    ION-LIST.search-results-list ION-ITEM ION-LABEL {
      margin-bottom: 7px; }
      ION-LIST.search-results-list ION-ITEM ION-LABEL H3 {
        margin-bottom: 7px; }
  ION-LIST.search-results-list ION-ITEM-OPTION {
    margin-bottom: 10px; }

ION-MODAL.config-modal .regisafe-content {
  --padding-start: 15px; }

/*# sourceMappingURL=regisafe-theme.css.map */
