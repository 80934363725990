:root {
  --regisafe-header-height: 104px;
  --regisafe-footer-height: 97px;
  --regisafe-footer-background: #F2F8FB;
  --regisafe-button-background: #F2F8FB;
  --regisafe-input-focused-background: #F2F8FB;
  --regisafe-button-background-light: #fff;
  --regisafe-button-background-danger: #FF8E95;
  --regisafe-button-color-danger: #F2F8FB;
  --regisafe-tabbar-with: 60px;
  --regisafe-tabbar-top: 56px;
  --regisafe-tabbar-height: calc(100% - (173px));
  --regisafe-tabbar-left: -65px;
  --regisafe-tabbar-background: transparent;
  --regisafe-tabbar-button-width: 50px;
  --regisafe-tabbar-button-height: 50px;
  --regisafe-tabbar-button-background: #FFF;
  --regisafe-tabbar-button-selected-background: #C8D3E2;
  --regisafe-font-color: #777777;
  --regisafe-danger-color: #FF3B30;
  --regisafe-icon-color-gray: rgba(54, 54, 54, 0.7);
  --regisafe-content-margin-left: 80px;
  --regisafe-content-margin-top: 0;
  --regisafe-content-margin-right: 10px; }

ION-TABS ION-TAB-BAR {
  --border: none;
  --background: var(--regisafe-tabbar-background); }
ION-TABS ION-TAB-BUTTON {
  --color: var(--regisafe-font-color);
  --color-selected: #FFF; }
ION-TABS.bottom ION-TAB-BAR {
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: start; }
ION-TABS.bottom ION-TAB-BUTTON {
  max-height: var(--regisafe-tabbar-button-height);
  height: var(--regisafe-tabbar-button-height);
  min-height: var(--regisafe-tabbar-button-height);
  margin: 0 0 20px 0; }
ION-TABS.left {
  flex-direction: column; }
  ION-TABS.left .ion-page {
    left: 0; }
  ION-TABS.left ION-TAB-BAR {
    position: absolute;
    width: var(--regisafe-tabbar-with);
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    left: 11px;
    transition: all ease-in-out 0.25s;
    padding-right: 6px;
    margin-top: env(safe-area-inset-top);
    justify-content: center;
    padding-top: 20px;
    height: var(--regisafe-tabbar-height);
    top: var(--regisafe-tabbar-top); }
    @media screen and (max-height: 767px) {
      ION-TABS.left ION-TAB-BAR {
        justify-content: start; } }
    ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON {
      height: var(--regisafe-tabbar-button-height);
      max-height: var(--regisafe-tabbar-button-height);
      min-height: var(--regisafe-tabbar-button-height);
      width: var(--regisafe-tabbar-button-width);
      max-width: var(--regisafe-tabbar-button-width);
      min-width: var(--regisafe-tabbar-button-width);
      --padding-start: 0;
      --padding-end: 0;
      --padding-bottom: 0;
      --padding-top: 0;
      margin: 0 0 15px 0;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      background-color: var(--regisafe-tabbar-button-background);
      pointer-events: all;
      position: relative; }
      ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON.tab-selected {
        box-shadow: none; }
        ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON.tab-selected:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.2); }
      ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON ION-ICON {
        font-size: 1.7rem;
        margin: 0;
        color: var(--regisafe-font-color); }
      ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON ION-LABEL {
        font-size: 0.6rem;
        white-space: break-spaces;
        line-height: 0.8rem;
        hyphens: auto;
        display: none;
        color: var(--regisafe-font-color); }
      ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON LABEL {
        display: none; }
      ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON.dateien-in-bearbeitung {
        position: relative; }
        ION-TABS.left ION-TAB-BAR ION-TAB-BUTTON.dateien-in-bearbeitung::after {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: #FB4343;
          top: 5px;
          right: 5px;
          border-radius: 4px; }
ION-TABS.hide-tab-bar ION-TAB-BAR {
  transition: all ease-in-out 0.25s;
  left: var(--regisafe-tabbar-left); }
ION-TABS.hide-tab-bar ION-CONTENT.regisafe-content DIV.inner-wrapper {
  padding-top: 0;
  padding-right: 15px;
  padding-bottom: 0;
  padding-left: 15px; }
  ION-TABS.hide-tab-bar ION-CONTENT.regisafe-content DIV.inner-wrapper.no-padding {
    padding: 0; }
  ION-TABS.hide-tab-bar ION-CONTENT.regisafe-content DIV.inner-wrapper.left {
    margin-left: 0px; }
  ION-TABS.hide-tab-bar ION-CONTENT.regisafe-content DIV.inner-wrapper.center {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center; }
  ION-TABS.hide-tab-bar ION-CONTENT.regisafe-content DIV.inner-wrapper.show-items-as-column {
    flex-direction: column; }

/*# sourceMappingURL=regisafe-tabbar.css.map */
