/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/regisafe-tabbar.css";
@import "theme/regisafe-theme.css";
@import "theme/regisafe-searchforms.css";
@import "theme/regisafe-modal-listart.css";
@import "theme/regisafe-files-inprogress.css";
@import "theme/regisafe-login.css";


.alert-wrapper.sc-ion-alert-md {
  max-width: 600px;
  min-width: 400px;
}
//.alert-radio-label.sc-ion-alert-md {
//  white-space: normal !important;
//}

// Safe Area set as global CSS var for Access them in TypeScript
//
:root {
  --regisafeSafeAreaT: env(safe-area-inset-top);
  --regisafeSafeAreaR: env(safe-area-inset-right);
  --regisafeSafeAreaB: env(safe-area-inset-bottom);
  --regisafeSafeAreaL: env(safe-area-inset-left);
}



